<template>
    <Layout 
      class="container" 
      :panels="panels" 
      style="background-color: #01132C;" 
      :padding="10" 
      :gutter="10" 
      :loading="loading"
    />
</template>

<script>
import Layout from '@/components/Layout'
import moment from 'moment'
import UTIL from '@/utils'
moment.locale('zh-cn')

import {mapGetters} from 'vuex'
export default {
  name: "test1",
  components:{Layout},
 
  computed:{
    
    ...mapGetters('board',['config','loading','current_contract_count','current_contract_amount','untransferred_amount','transfer_percent','typed_projects','other_projects','recent_bills','recent_transfers','year','actual_pv','total_pv','total_pv_percent','bill_plan_amount','bill_amount','bill_percent','yearTransData','yearBillData','hr_projects','plan_hrvalue','actual_hrvalue','hrbill','hr_amount','hr_plan_amount','hr_percent','plan_hrcount','actual_hrcount']),
    panels(){
      return [{
        x:0.3,y:0.2,w:8,h:1,
        control:"PanelCaption",
        data:{
          name:"宁波高专数字化成本合约信息平台",
          sub:"Ningbo Gaozhuan Digital Contract Cost Information Platform"
        }
      },{
        x:0.1,y:1.3,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"在建项目总数 (规模以上)",
          border:8,
          value:this.current_contract_count,
          toFixed:0,
          em:true,
          unit:"个" 
        }
      },{
        x:3.1,y:1.3,w:3,h:1.2,
        control:"PanelDvNumboard",
        data:{
          name:"剩余合同总额",
           border:8,
          value:this.current_contract_amount,
          unit:'万元',
          
          em:true,
          toFixed:2,
        }
      },{
        x:0.1,y:6.5,w:3,h:1.2,control:"PanelDvNumboard",
        data:{
          name:"历史未到账总额",
           border:8,
           value:this.untransferred_amount,
           em:true,
          unit:'万元',
          toFixed:2,
        }
      },{
        x:3.1,y:6.5,w:3,h:1.2,control:"PanelDvNumboard",
        data:{
          name:`回款率(${this.year})`,
           border:8,
          value:this.config.year_transfer_percent,
           toFixed:2,
          unit:"%" 
        }
      },{
        x:0.1,y:2.5,w:6,h:4,control:"PanelDvChartTypePie",data:{name:"在建项目比例",raw:this.config.typed_projects}
      },
      {
        x:0.1,y:7.7,w:6,h:3.3,control:"PanelDvProjects",data:{name:"历史未到账项目余款",raw:this.config.untransferred_projects,time:'year'}
      },
      // ,{
      //   x:7.5,y:3.2,w:5.5,h:3,control:"PanelDvBillMonthly",data:{name:`${this.year}年每月开票统计`,raw:this.yearBillData}
      // },
      // {
      //   x:13,y:3.2,w:5.5,h:3,control:"PanelDvBillMonthly",data:{name:`${this.year}年每月到账统计`,raw:this.yearTransData}
      // }
      // ,{
      //   x:7.5,y:6.2,w:5.5,h:4.8,control:"PanelDvRecentBill",data:{name:`${this.year}年最新开票情况`,raw:this.recent_bills}
      // },
      //  ,{
      //   x:13,y:6.2,w:5.5,h:4.8,control:"PanelDvRecentBill",data:{name:`${this.year}年最新到账情况`,raw:this.recent_transfers}
      // },
      {
        x:18.5,y:3.8,w:5.5,h:3.2,control:"PanelDvMap",data:this.config.locations
      },
      {
        x:18.5,y:7,w:5.5,h:4,control:"PanelDvRecentComplete",data:{name:this.year+"年在建项目年预测人均产值排行",raw:this.config.hra_projects}
      },
      {
        x:6.2,y:1,w:6.3,h:4.4,
        control:"PanelBlProduction",
      },
       {
        x:6.2,y:5.4,w:6.3,h:2.1,
        control:"PanelDvProjectPercent",
        data:{
          name:'当前在建项目产值进度分布',
          raw:this.config.progress_grouped_data,
        }
      },
      {
        x:12.5,y:1,w:6,h:6.5,
        control:"PanelBlBillplan",
      },
      {
        x:14.5,y:0.3,w:9.5,h:1.5,control:"PanelTime"
      },{
        x:18.5,y:2.6,w:2.75,h:1.2,control:"PanelDvNumboard",
        data:{
          name:"当前人均产值",
          value:this.config.year_hra_production,
          border:8,
           toFixed:2,
           em:true,
          unit:'万元',
        }
      },{
        x:21.25,y:2.6,w:2.75,h:1.2,control:"PanelDvNumboard",
        data:{
          name:"当前人均收费",
          value:this.config.year_actual_hra_bill,
          border:8,
          em:true,
           toFixed:2,
          unit:'万元'
        }
      },{
        x:18.5,y:1.4,w:2.75,h:1.2,control:"PanelDvNumboard",
        data:{
          name:"2023年计划投入人数",
          border:8,
          value:this.config.year_plan_hrv?(this.config.year_plan_hrv).toFixed(1):0,
           toFixed:2,
          unit:"人" 
        }
      },{
        x:21.25,y:1.4,w:2.75,h:1.2,control:"PanelDvNumboard",
        data:{
          name:"2023年预测投入人数",
          border:8,
          value:this.config.year_predict_hrv?(this.config.year_predict_hrv).toFixed(1):0,
           toFixed:2,
          unit:"人" 
        }
      },  {
        x:6.2,y:7.5,w:6.3,h:3.5,control:"PanelDvProjects",data:{name:`2023年竣工项目`,raw:this.config.completed_projects,border:8}
      },
      {
        x:12.5,y:7.5,w:6,h:3.5,control:"PanelDvProjects",data:{name:`6个月内将竣工项目`,raw:this.config.in_complete_projects,border:8,time:'year'}
      },]
    }
  },
}
</script>