<template>
  <div class="pm-page">
    <div class="pm-layout pm-layout-config">
    <BaseEmpty  v-if="loading || _loading">
    <dv-loading style="color:#3ef">Loading...</dv-loading>
    </BaseEmpty>
    <div class="pm-fix" v-if="!loading && !_loading">
      <template v-for="p in panels.filter(p=>p.option && p.option.fixed)">
        <div class="pm-panel" :style="`
          left:${p.x * (unitx+gutter) }px;
          top:${p.y * (unity+gutter)}px;
          width:${p.w * (unitx+gutter) - gutter}px;
          height:${p.h * (unity+gutter) - gutter}px;
          opacity:1;
         zoom:${zoom||1};
          overflow:hidden;
        `" :key="p.id">
            <keep-alive>
            <component :is="p.control" @event="handleEvent" :data="p.data" :env="env" :option="p.option" :pause="pause"  />
            </keep-alive>
        </div>
      </template>
    </div>
    
    <div class="pm-content" :class="flex?'pm-content-flex':''"  v-if="!loading&& !_loading">
     
      <template v-for="p in panels.filter(p=>!p.option || !p.option.fixed)">
        <div class="pm-panel" 
        
        :style="(!flex?`left:${padding + p.x * (unitx+gutter)-gutter }px;
          top:${padding + p.y * (unity+gutter)-gutter}px;
          width:${p.w * (unitx+gutter) - gutter}px;
          height:${p.h * (unity+gutter) - gutter}px;
          opacity:1;
          overflow:hidden;display:${loading?'none':'block'};
          
         zoom:${zoom||1};`:`position:relative;
          width:calc(${(p.w *100/24)}% - ${gutter}px);
          min-height:${p.h * unity}px;
           overflow:hidden;display:${loading?'none':'block'};
         zoom:${zoom||1};
         margin:${gutter/2}px
        `) + ';'+panelStyles
        " :key="p.id">
            <keep-alive>
            <component :is="p.control" @event="handleEvent" :data="p.data" :env="env" :option="p.option" :pause="pause"  />
            </keep-alive>
        </div>
      </template>
    </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import responsableMixin from '@/mixins/responsable'
export default {
  data(){
    return {
        loading:false,
        _loading:false,
      clientWidth:0,
     
      clientHeight:0,
      unitx:0,
      unity:0,
      zoom:1,
      unit:100,
      
    }
  },
  mixins:[responsableMixin],
  props:{
    'panels':{
      type:Array,
      default:()=>[]
    },
    'loading':{
      type:Boolean
    } ,'padding':{
      type:Number,
      default:0
    }, 'gutter':{
      type:Number,
      default:10
    },'env':{
      type:Object,
      default:()=>({})
    },'flex':{
      type:Boolean,
      default:false
    },'panel-styles':{
      type:String
    },
    'pause':{
      type:Boolean
    }
  },
  mounted(){
   

    this.$nextTick(e=>{
      this.resetLayout()

      window.addEventListener('resize',this.resetLayout)
    })  

    
    
  },
  activated(){
     this.$nextTick(e=>{
      this.resetLayout()
    })
  },
  methods:{
    resetLayout(){
      if(this.mobile)
        return
     this._loading=true
       this.clientWidth = this.$el.clientWidth
    this.clientHeight = this.$el.clientHeight

    
    let ratio = this.clientHeight / this.clientWidth 
    this.maxColumns = ''
    this.maxRows = ''
    this.unitx = (this.clientWidth + this.gutter- 2 * this.padding) / 24 - this.gutter 
     //(this.clientHeight + this.gutter ) / 11 - this.gutter 
     
    let unity = this.layoutScrollable?(this.clientHeight) / this.clientWidth * this.unitx - this.gutter:(this.clientHeight + this.gutter - 2 * this.padding)  / parseInt(24 * ratio) - this.gutter
   

    if(this.clientHeight - 11 * unity <= 450)
       unity = (this.clientHeight + this.gutter - 2* this.padding ) / 11 - this.gutter 
    
    this.unity = unity
   
    setTimeout(()=>{
       this._loading =false
       this.$forceUpdate()
    },500)
   },
     handleEvent(e){
        this.$emit('event',e)

      },
      onResize(){
        this.resetLayout()
      }
  }
}
</script>

<style lang="less">
.pm-panel{
}
.pm-panel-flex{
  display: flex;
}
.pm-panel-editing-wrap:hover{
  filter:brightness(1.2);
  cursor: pointer;
}

.pm-panel-selected{
  background:#fbbd2162 !important;
}

.pm-content-mobile{
  display: block;
  height:100%;
  overflow-y:auto;
}
</style>