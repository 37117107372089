var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pm-page"},[_c('div',{staticClass:"pm-layout pm-layout-config"},[(_vm.loading || _vm._loading)?_c('BaseEmpty',[_c('dv-loading',{staticStyle:{"color":"#3ef"}},[_vm._v("Loading...")])],1):_vm._e(),(!_vm.loading && !_vm._loading)?_c('div',{staticClass:"pm-fix"},[_vm._l((_vm.panels.filter(p=>p.option && p.option.fixed)),function(p){return [_c('div',{key:p.id,staticClass:"pm-panel",style:(`
        left:${p.x * (_vm.unitx+_vm.gutter) }px;
        top:${p.y * (_vm.unity+_vm.gutter)}px;
        width:${p.w * (_vm.unitx+_vm.gutter) - _vm.gutter}px;
        height:${p.h * (_vm.unity+_vm.gutter) - _vm.gutter}px;
        opacity:1;
       zoom:${_vm.zoom||1};
        overflow:hidden;
      `)},[_c('keep-alive',[_c(p.control,{tag:"component",attrs:{"data":p.data,"env":_vm.env,"option":p.option,"pause":_vm.pause},on:{"event":_vm.handleEvent}})],1)],1)]})],2):_vm._e(),(!_vm.loading&& !_vm._loading)?_c('div',{staticClass:"pm-content",class:_vm.flex?'pm-content-flex':''},[_vm._l((_vm.panels.filter(p=>!p.option || !p.option.fixed)),function(p){return [_c('div',{key:p.id,staticClass:"pm-panel",style:((!_vm.flex?`left:${_vm.padding + p.x * (_vm.unitx+_vm.gutter)-_vm.gutter }px;
        top:${_vm.padding + p.y * (_vm.unity+_vm.gutter)-_vm.gutter}px;
        width:${p.w * (_vm.unitx+_vm.gutter) - _vm.gutter}px;
        height:${p.h * (_vm.unity+_vm.gutter) - _vm.gutter}px;
        opacity:1;
        overflow:hidden;display:${_vm.loading?'none':'block'};
        
       zoom:${_vm.zoom||1};`:`position:relative;
        width:calc(${(p.w *100/24)}% - ${_vm.gutter}px);
        min-height:${p.h * _vm.unity}px;
         overflow:hidden;display:${_vm.loading?'none':'block'};
       zoom:${_vm.zoom||1};
       margin:${_vm.gutter/2}px
      `) + ';'+_vm.panelStyles)},[_c('keep-alive',[_c(p.control,{tag:"component",attrs:{"data":p.data,"env":_vm.env,"option":p.option,"pause":_vm.pause},on:{"event":_vm.handleEvent}})],1)],1)]})],2):_vm._e()],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }